<template>
  <v-app>
    <Modal />

    <Header id="header" />

    <v-container
      style="flex-grow: 1"
      v-if="$store.state.breadcrumbs.length > 1"
    >
      <v-btn-toggle
        v-model="toggle_exclusive"
        rounded
        dense
      >
        <v-btn
          v-for="(item, idx) in $store.state.breadcrumbs"
          :key="idx"
          :to="item.route"
          outlined
          color="primary"
          :class="`caption ${item.route === $route.path ? 'active' : ''}`"
        >
          <span v-if="idx === 0">
            <v-icon
              class="px-1"
              color="primary"
              x-small
            >
              fas fa-home
            </v-icon>
            {{ item.title }}
          </span>
          <span
            v-else
            class="pl-1 pr-2"
            :style="{ color: item.route === $route.path ? 'white' : $primary }"
          >
            <v-icon
              class="pr-1"
              :color="item.route === $route.path ? 'white' : 'primary'"
              x-small
            >
              fas fa-chevron-right
            </v-icon>
            {{ item.title }}
          </span>
        </v-btn>
      </v-btn-toggle>
    </v-container>

    <router-view />

    <Footer id="footer" />
  </v-app>
</template>

<script>
export default {
  components: {
    Header: () => import('@/components/web/layout/Header'),
    Footer: () => import('@/components/web/layout/Footer'),
    Modal: () => import('@/components/modal/Modal'),
  },
  data: () => ({
    toggle_exclusive: null,
  }),
}
</script>

<style scoped lang="scss">
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
.active {
  background: $primary;
  color: white !important;
}
.active .v-icon {
  color: white !important;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: $primary !important;
}
</style>
